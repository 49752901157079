<template>
<div>
    <div class="columns">
        <div class="column is-12 has-text-centered">
            <h1 class="title">Nielsen DMA Map</h1>
            <h2 class="subtitle">Shape data from 2013</h2>
        </div>
    </div>
    
    <div class="columns" id="map-container">
        <div class="column">
            <div id="map" style="text-align: center;" v-once></div>
            <div id="textbox"></div>
        </div>
    </div>
</div>
</template>

<style>

.pagedma-tool {
    padding-left: 20px !important;
}

.background {
  fill: none;
  pointer-events: all;
}

.graticule {
  fill: none;
  stroke: #777;
  stroke-width: .5;
  stroke-opacity: .7;
}

#dma-borders {
  fill: none;
  stroke: black;
  stroke-width: 0.7;
  stroke-linejoin: round;
  stroke-linecap: round;
  pointer-events: none;
}

#textbox {
  position: relative;
  bottom: 0;
  left: 50px;
  width: 275px;
  height: 50px;
}

#textbox text p {
  font-family: Arial, Helvetica;
  font-size: .8em;
  margin: 0;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 60%; /* aspect ratio */
  vertical-align: top;
  overflow: hidden;
}

.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}
</style>

<script>
import "@/assets/js/d3.v3.js";
import nielsenData from "@/assets/js/nielsentopo.json"
import * as topojson from "topojson-client";

export default {
    name: "dma-map",
    data: function() {
        return {
            width: 960,
            height: 500,
            scale: 1070,
            nielsenData: null
        }
    },
    props: [
        'dmas', 'selectedDMA'
    ],
    mounted() {
        this.initD3()
    },  
    methods: {
        initD3() {
            const vueInstance = this

            // load additional data
            var nielsen = nielsenData.objects.nielsen_dma.geometries
            
            for (var i=0; i<nielsen.length; i++) {
                var dma_code = nielsen[i].id
                for (const key in this.dmas[dma_code]) {
                    nielsen[i].properties[key] = this.dmas[dma_code][key]
                }
            }
            nielsenData.objects.nielsen_dma.geometries = nielsen
            this.nielsenData = nielsenData

            // build map
            var projection = d3.geo.albersUsa()
            .scale(1070) // size, bigger is bigger
            .translate([this.width / 2, this.height / 2]);

            var path = d3.geo.path().projection(projection)
            var xScale = d3.scale.linear()
                .domain([0, 7])
                .range([0, 500])
            
            var xAxis = d3.svg.axis()
                .scale(xScale)
                .orient("bottom")
                .tickSize(13)
                .tickFormat(d3.format("0.0f"))
            
            // set svg window
            var svg = d3.select('#map')
                .classed("svg-container", true) 
                .append("svg")
                .attr("preserveAspectRatio", "xMinYMin meet")
                .classed("svg-content-responsive", true)
                //.attr("width", this.width)
                //.attr('height', this.height)
                .attr('viewBox', `0 0 ` + this.width + ` ` + this.height)
                .attr("id", "svgMap")
            
            var graticule = d3.geo.graticule()
                .extent([ -98-45, 38-45], [-98 + 45, 38 + 45])
                .step([5, 5])

            // add a blank background
            var defaultFill = "#fff"
            svg.append("rect")
                .datum(graticule)
                .attr("class", "background")
                .attr("width", this.width)
                .attr("height", this.height)

            // declare g as our appended svg
            var g = svg.append('g')
            g.append("g")
                .attr("id", "dmas")
                .selectAll("path")
                .data(topojson.feature(nielsenData, nielsenData.objects.nielsen_dma).features)
                .enter()
                .append("path")
                .attr('id', function(d, i) {
                    return "dma" + d.properties.dma;
                })
                .attr("d", path)
                .on("click", function(d) {
                    d3.selectAll("path")
                        .attr("fill","#fff")
                    d3.select(this)
                        .attr("fill", "blue");
                    vueInstance.clicked(d)
                })
                .on("mouseover", function(d) {
                    var dma = d3.select(this)
                    d3.select(this).attr("fill", 'orange')
                    
                    var prop = d.properties
                    var string = "<p><strong>Market Area Name</strong>: " + prop["dma1"] + "</p>"
                    
                    d3.select("#textbox")
                        .html("")
                        .append("text")
                        .html(string)
                })

                // add mouse out
                .on("mouseout", function(d) {
                    if (d3.select(this).attr("fill") === "orange") {
                        d3.select(this)
                            .attr("fill", defaultFill)
                        d3.select("#textbox")
                            .html("")
                            .text("");
                    };
                })

                .attr("opacity", 0.9)
                .attr("fill", defaultFill)

            // add DMA borders
            g.append("path", ".graticule")
                .datum(topojson.mesh(nielsenData, nielsenData.objects.nielsen_dma, function(a, b) {
                    return true
                }))
                .attr("id", "dma-borders")
                .attr("d", path)
        },
        clicked: function(dma) {
            this.$emit("choseDMA", dma.id)
        }
    },
    watch: {
        selectedDMA: function() {
            if (this.selectedDMA) {
                d3.selectAll("path")
                    .attr("fill","#fff");

                d3.selectAll("#dma" + this.selectedDMA.code)
                    .attr("fill", "blue")
            }
        }
    }
}
</script>