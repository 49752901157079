import axios from 'axios'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { toast, Toast } from "bulma-toast"

axios.defaults.baseURL = process.env.VUE_APP_BASEURL

// Axios Interceptor
axios.interceptors.response.use(undefined, function(error) {
    if (error) {
        const originalRequest = error.config

        if (error.response.status === 401) {
            originalRequest._retry = true;
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            localStorage.removeItem("username")
            store.commit('removeToken')
            toast({
                message: "Authentication has expired. Please login again.",
                type: "is-danger",
                position: "top-center",
                pauseOnHover: true,
                dismissible: true,
                duration: 3000
            })
            router.push("/login")
        }
    }
})

createApp(App).use(store).use(router).mount('#app')
