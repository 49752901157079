<template>
<div class="columns">
    <div class="column is-8 is-offset-1">
        <table class="table" id="tblStations">
            <thead>
                <tr>
                    <th>Station</th>
                    <th>Affiliate</th>
                    <th>Station Group</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="station in this.dma.stations" v-bind:key="station.id">
                    <td>{{ station.call_letters }}</td>
                    <td>{{ station.affiliation }}</td>
                    <td>{{ station.station_group }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
// jquery
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'
import PdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts";
import jsZip from "jszip"
window.JSZip = jsZip
PdfMake.vfs = pdfFonts.pdfMake.vfs
window.PdfMake = PdfMake
import '@/assets/css/dataTables.bulma.min.css'
import '@/assets/css/buttons.bulma.min.css'


export default {
    data: function() {
        return {
            table: null
        }
    },
    name: "table-station",
    mounted() {
        this.drawTable()
    },
    methods: {
        drawTable() {
            if (this.table) {
                this.table.destroy()
            }
            this.$nextTick(() => {
                this.table = $("#tblStations").DataTable({
                    ordering: true,
                        "paging": true,
                        dom: 'Blfrtip',
                        buttons: [
                            'copyHtml5',
                            {
                                extend: 'excelHtml5',
                                title: 'countyData'
                            },
                            {
                                extend: 'csvHtml5',
                                title: 'countyData'
                            },
                        ],
                })
            })
        }
    },
    props: [
        'dma'
    ],
    watch: {
        dma(newVal, oldVal) {
            this.drawTable()
        }
    }
}
</script>

<style scoped>
</style>