<template>
    <div class="page-samplecalculator">
        <div class="columns">
            <div class="column is-6 is-offset-3">
                <div class="panel">
                    <div class="panel-heading">
                        <h1 class="title">Sample Calculator</h1>
                    </div>
                    <div class="panel-block">
                        <div class="columns" style="width: 100%">
                            <div class="column is-8">
                                <div class="columns" style="width: 100%">
                                    <div class="column is-12">
                                        <table class="table borderless" style="width: 100%">
                                            <tbody>
                                                <tr>
                                                    <td class="cell-header">Market</td>
                                                    <td colspan="2">
                                                        <v-select v-model="selectedDMA"
                                                            placeholder="Select a DMA"
                                                            id="dma_dropdown" 
                                                            :options="this.dmas"
                                                            label="display"
                                                            key="code"
                                                            @option:selected="getSelectedDMA($event)"
                                                        >
                                                        </v-select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="cell-header">Sample Size</td>
                                                    <td colspan="2">
                                                        <input class="input" type="text" v-model="this.sampleSize" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="cell-header">Age Range</td>
                                                    <td>
                                                        <select class="input" v-model="this.selectedLowAge">
                                                            <option v-for="age in this.lowAges" v-bind:key="age.id">{{ age.age }}</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select class="input" v-model="this.selectedHighAge">
                                                            <option v-for="age in this.highAges" v-bind:key="age.id">{{ age.age }}</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="cell-header">Gender</td>
                                                    <td colspan="2">
                                                        <select class="input" v-model="this.selectedGender">
                                                            <option value="both">Both</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="cell-header">Year</td>
                                                    <td colspan="2">
                                                        <select class="input" v-model="this.selectedYear">
                                                            <option v-for="( year, idx ) in this.years" :key="idx">
                                                                {{ year }}
                                                            </option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-4 has-text-centered">
                                <h2 class="title" style="margin-bottom: 0px"><strong>Incidence Level</strong></h2>
                                <p class="small">(12-99 year olds)</p>
                                <p class="title" style="margin-top: 15px">{{ this.incidence }}%</p>
                                <div class="column is-4 has-text-centered">
                                    <button class="button is-info" v-on:click="this.resetOptions">Reset</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns" v-if="this.dma">
            <div class="column is-6 is-offset-3">
                <div class="panel">
                    <div class="panel-block">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12">
                                <div class="columns" style="width: 100%">
                                    <div class="column is-12">
                                        <table id="tblCounts" class="table is-striped" style="width: 100%" v-if="this.dma">
                                            <thead>
                                                <tr>
                                                    <th>Age Range</th>
                                                    <th>Men</th>
                                                    <th>Women</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>12-14</td>
                                                    <td>{{ this.male_12_to_14 }}</td>
                                                    <td>{{ this.female_12_to_14 }}</td>
                                                    <td>{{ this.total_12_to_14 }}</td>
                                                </tr>
                                                <tr>
                                                    <td>15-17</td>
                                                    <td>{{ this.male_15_to_17 }}</td>
                                                    <td>{{ this.female_15_to_17 }}</td>
                                                    <td>{{ this.total_15_to_17 }}</td>
                                                </tr>
                                                <tr>
                                                    <td>18-24</td>
                                                    <td>{{ this.male_18_to_24 }}</td>
                                                    <td>{{ this.female_18_to_24 }}</td>
                                                    <td>{{ this.total_18_to_24 }}</td>
                                                </tr>
                                                <tr>
                                                    <td>25-34</td>
                                                    <td>{{ this.male_25_to_34 }}</td>
                                                    <td>{{ this.female_25_to_34 }}</td>
                                                    <td>{{ this.total_25_to_34 }}</td>
                                                </tr>
                                                <tr>
                                                    <td>35-44</td>
                                                    <td>{{ this.male_35_to_44 }}</td>
                                                    <td>{{ this.female_35_to_44 }}</td>
                                                    <td>{{ this.total_35_to_44 }}</td>
                                                </tr>
                                                <tr>
                                                    <td>45-49</td>
                                                    <td>{{ this.male_45_to_49 }}</td>
                                                    <td>{{ this.female_45_to_49 }}</td>
                                                    <td>{{ this.total_45_to_49 }}</td>
                                                </tr>
                                                <tr>
                                                    <td>50-54</td>
                                                    <td>{{ this.male_50_to_54 }}</td>
                                                    <td>{{ this.female_50_to_54 }}</td>
                                                    <td>{{ this.total_50_to_54 }}</td>
                                                </tr>
                                                <tr>
                                                    <td>55-64</td>
                                                    <td>{{ this.male_55_to_64 }}</td>
                                                    <td>{{ this.female_55_to_64 }}</td>
                                                    <td>{{ this.total_55_to_64 }}</td>
                                                </tr>
                                                <tr>
                                                    <td>65 plus</td>
                                                    <td>{{ this.male_65_plus }}</td>
                                                    <td>{{ this.female_65_plus }}</td>
                                                    <td>{{ this.total_65_plus }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>Totals</th>
                                                    <th>0</th>
                                                    <th>0</th>
                                                    <th>0</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns" v-if="this.dma">
            <div class="column is-6 is-offset-3">
                <div class="panel">
                    <div class="panel-block">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12">
                                <div class="columns" style="width: 100%">
                                    <div class="column is-12">
                                        <table id="tblPercent" class="table is-striped" style="width: 100%" v-if="this.dma">
                                            <thead>
                                                <tr>
                                                    <th>Age Range</th>
                                                    <th>Men</th>
                                                    <th>Women</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>12-14</td>
                                                    <td>{{  fixNaN(((this.male_12_to_14 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.female_12_to_14 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.total_12_to_14 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                </tr>
                                                <tr>
                                                    <td>15-17</td>
                                                    <td>{{  fixNaN(((this.male_15_to_17 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.female_15_to_17 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.total_15_to_17 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                </tr>
                                                <tr>
                                                    <td>18-24</td>
                                                    <td>{{  fixNaN(((this.male_18_to_24 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.female_18_to_24 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.total_18_to_24 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                </tr>
                                                <tr>
                                                    <td>25-34</td>
                                                    <td>{{ fixNaN(((this.male_25_to_34 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.female_25_to_34 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.total_25_to_34 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                </tr>
                                                <tr>
                                                    <td>35-44</td>
                                                    <td>{{ fixNaN( ((this.male_35_to_44 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{ fixNaN( ((this.female_35_to_44 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{ fixNaN( ((this.total_35_to_44 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                </tr>
                                                <tr>
                                                    <td>45-49</td>
                                                    <td>{{  fixNaN(((this.male_45_to_49 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.female_45_to_49 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.total_45_to_49 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                </tr>
                                                <tr>
                                                    <td>50-54</td>
                                                    <td>{{  fixNaN(((this.male_50_to_54 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.female_50_to_54 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.total_50_to_54 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                </tr>
                                                <tr>
                                                    <td>55-64</td>
                                                    <td>{{  fixNaN(((this.male_55_to_64 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.female_55_to_64 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.total_55_to_64 / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                </tr>
                                                <tr>
                                                    <td>65 plus</td>
                                                    <td>{{  fixNaN(((this.male_65_plus / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.female_65_plus / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                    <td>{{  fixNaN(((this.total_65_plus / this.sampleSize) * 100).toFixed(1)) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.page-samplecalculator {
    padding: 10px;
}

.cell-header {
    width: 120px;
    padding-top: 15px;
}

.borderless td {
    border: 0px
}
</style>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'


// jquery
import 'jquery/dist/jquery.min.js'

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'
import PdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import jsZip from "jszip"
window.JSZip = jsZip
PdfMake.vfs = pdfFonts.pdfMake.vfs
window.PdfMake = PdfMake
import '@/assets/css/dataTables.bulma.min.css'
import '@/assets/css/buttons.bulma.min.css'

// VueSelect
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    name: "SampleCalculator",
    components: {
        vSelect
    },
    data() {
        return {
            years: [
                2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011
            ],
            dmas: [],
            lowAges: [
                {'id': 0, 'age': 12},
                {'id': 1, 'age': 15},
                {'id': 2, 'age': 18},
                {'id': 3, 'age': 25},
                {'id': 4, 'age': 35},
                {'id': 5, 'age': 45},
                {'id': 6, 'age': 50},
                {'id': 7, 'age': 55},
                {'id': 8, 'age': 65},
            ],
            highAges: [
                {'id': 0, 'age': 14},
                {'id': 1, 'age': 17},
                {'id': 2, 'age': 24},
                {'id': 3, 'age': 34},
                {'id': 4, 'age': 35},
                {'id': 5, 'age': 44},
                {'id': 6, 'age': 49},
                {'id': 7, 'age': 54},
                {'id': 8, 'age': 64},
                {'id': 9, 'age': 99}
            ],
            sampleSize: 400,
            selectedLowAge: 12,
            selectedHighAge: 99,
            selectedGender: "both",
            selectedDMA: null,
            dma: null,
            selectedYear: 2021,
            countTable: null,
            percentTable: null,
            nationalData: null
        }
    },
    mounted() {
        document.title = "Sample Calculator | SG Tools"
        this.$store.commit("setIsLoading", false)
        this.getAllDMAs()
        this.getNationalDMA()
    },
    methods: {
        fixNaN(val) {
            if (isNaN(val)) {
                return null
            }

            if (val == 0) {
                return null
            }
            return val + "%"
        },
        async getAllDMAs() {
            try {
                this.$store.commit("setIsLoading", true)
                toast({
                    message: "Loading DMA Data. Please wait...",
                    type: 'is-danger',
                    dismissible: true
                })
                const response = await axios.get('dma/')
                const total = response.data.count
                const perPage = response.data.results.length
                const totalPages = Math.ceil(total / perPage)
                const promiseArray = []

                for (let i=0; i < (totalPages); i++) {
                    promiseArray.push(axios.get(`dma/?page=${i+1}`))
                };

                // promise.all alls you to make multiple axios requests at the same time
                // It returns an array of the results
                let resolvedPromises = await Promise.all(promiseArray)

                let dmas = resolvedPromises.reduce(function(arr, row){
                    return arr.concat(row.data.results)
                }, [])

                dmas.unshift({
                    'id': 1,
                    'code': 1,
                    'display': "National"
                })

                this.dmas = dmas
                var dma_dict = {}
                for (let i=0; i < this.dmas.length; i++) {
                    dma_dict[this.dmas[i].code] = {
                        "name": this.dmas[i].name
                    }
                }
                this.dma_dict = dma_dict
                this.$store.commit("setIsLoading", false)
                toast({
                    message: "Ready!",
                    type: 'is-success'
                })
            } catch  (err) {
                console.log('[!] ERROR: Something went wront')
                console.log(err)
                toast({
                    message: "[!] ERROR: Something went wrong getting all DMAs" + "\n" + err,
                    type: 'is-danger',
                    dismissible: true
                })
            }
        },
        async getSelectedDMA(event) {
            this.$store.commit('setIsLoading', true)
            
            // get the national data if selected
            if (event.code == 1) {
                this.getNationalDMA()
                return
            }

            try {
                await axios
                    .get('data/' + event.code + "/")
                    .then(response => {
                        this.dma = response.data
                        this.selectedYear = this.dma.years[0]
                        this.$store.commit('setIsLoading', false)
                    })
            } catch (err) {
                toast({
                    message: "[!] ERROR: Something went wrong getting selected DMAs" + "\n" + err,
                    type: 'is-danger',
                    dismissible: true
                })
            }
        },
        async getNationalDMA() {
            if (this.nationalData) {
                this.dma = this.nationalData
                this.$store.commit('setIsLoading', false)
                return
            }

            this.$store.commit('setIsLoading', true)
            try {
                await axios
                    .get('data/national/')
                    .then(response => {
                        this.dma = response.data
                        this.nationalData = response.data

                        if (!this.selectedYear) {
                            this.selectedYear = this.dma.years[0]
                        }
                        this.$store.commit('setIsLoading', false)
                    })
            } catch (err) {
                toast({
                    message: "[!] ERROR: Something went wrong getting selected DMAs" + "\n" + err,
                    type: 'is-danger',
                    dismissible: true
                })
            }
        },
        drawTable() {
            this.$nextTick(() => {
                if (this.countTable) {
                    this.countTable.destroy()
                }

                if (this.percentTable) {
                    this.percentTable.destroy()
                }
                this.countTable = $("#tblCounts").DataTable({
                    searching: false,
                    ordering: false,
                    "paging": false,
                    dom: 'Blfrt',
                    "columnDefs":[
                            {"className": "text-center", "targets": "_all"}
                    ],
                    buttons: [
                        {
                            extend: 'copyHtml5',
                            footer: true
                        },
                        {
                            extend: 'excelHtml5',
                            title: 'SampleCounts',
                            footer: true,
                        },
                        {
                            extend: 'csvHtml5',
                            title: 'SampleCounts',
                            footer: true,
                        },
                    ],
                    "footerCallback": function(row, data, start, end, display){
                        var api = this.api(), data;

                        var intVal = function(i){
                            i = i.toString();
                            return parseInt(i.replace(/,/g, ''), 10)
                        };

                        var footerMale = api
                            .column(1)
                            .data()
                            .reduce(function(a,b){
                                return intVal(a) + intVal(b)
                            },0);

                        var footerFemale = api
                            .column(2)
                            .data()
                            .reduce(function(a,b){
                                return intVal(a) + intVal(b)
                            },0);

                        var footerTotal = api
                            .column(3)
                            .data()
                            .reduce(function(a,b){
                                return intVal(a) + intVal(b)
                            },0);

                        // update footer
                        $(api.column(1).footer()).html(footerMale);
                        $(api.column(2).footer()).html(footerFemale);
                        $(api.column(3).footer()).html(footerTotal);
                    }
                })

                this.percentTable = $("#tblPercent").DataTable({
                    searching: false,
                    ordering: false,
                    "paging": false,
                    dom: 'Blfrt',
                    "columnDefs":[
                            {"className": "text-center", "targets": "_all"}
                    ],
                    buttons: [
                        {
                            extend: 'copyHtml5',
                            footer: true
                        },
                        {
                            extend: 'excelHtml5',
                            title: 'SampleCounts',
                            footer: true,
                        },
                        {
                            extend: 'csvHtml5',
                            title: 'SampleCounts',
                            footer: true,
                        },
                    ],
                    "footerCallback": function(row, data, start, end, display){
                        var api = this.api(), data;

                        var intVal = function(i){
                            i = i.toString();
                            return parseInt(i.replace(/,/g, ''), 10)
                        };

                        var footerMale = api
                            .column(1)
                            .data()
                            .reduce(function(a,b){
                                return intVal(a) + intVal(b)
                            },0);

                        var footerFemale = api
                            .column(2)
                            .data()
                            .reduce(function(a,b){
                                return intVal(a) + intVal(b)
                            },0);

                        var footerTotal = api
                            .column(3)
                            .data()
                            .reduce(function(a,b){
                                return intVal(a) + intVal(b)
                            },0);

                        // update footer
                        $(api.column(1).footer()).html(footerMale);
                        $(api.column(2).footer()).html(footerFemale);
                        $(api.column(3).footer()).html(footerTotal);
                    }
                })
            })
        },
        resetOptions() {
            console.log(this.selectedDMA)
            this.selectedDMA = this.nationalData
            this.sampleSize = 400
            this.selectedLowAge = 12
            this.selectedHighAge = 99
            this.selectedGender = "both"
            this.selectedYear = 2021
        }
    },
    computed: {
        maleTotal() {
            var maleTotal = 0

            if (this.dma) {

                if ((this.selectedLowAge == 12) && (this.selectedHighAge >= 14)) {
                    maleTotal += this.dma['total'][this.selectedYear].men_12_to_14
                }

                if ((this.selectedLowAge <= 15) && (this.selectedHighAge >= 17 )) {
                    maleTotal += this.dma['total'][this.selectedYear].men_15_to_17
                }

                if ((this.selectedLowAge <= 18) && (this.selectedHighAge >= 24 )) {
                    maleTotal += this.dma['total'][this.selectedYear].men_18_to_24
                }

                if ((this.selectedLowAge <= 25) && (this.selectedHighAge >= 34 )) {
                    maleTotal += this.dma['total'][this.selectedYear].men_25_to_34
                }

                if ((this.selectedLowAge <= 35) && (this.selectedHighAge >= 44 )) {
                    maleTotal += this.dma['total'][this.selectedYear].men_35_to_44
                }

                if ((this.selectedLowAge <= 45) && (this.selectedHighAge >= 49 )) {
                    maleTotal += this.dma['total'][this.selectedYear].men_45_to_49
                }

                if ((this.selectedLowAge <= 50) && (this.selectedHighAge >= 54 )) {
                    maleTotal += this.dma['total'][this.selectedYear].men_50_to_54
                }

                if ((this.selectedLowAge <= 55) && (this.selectedHighAge >= 64 )) {
                    maleTotal += this.dma['total'][this.selectedYear].men_55_to_64
                }

                if ((this.selectedLowAge <= 65) && (this.selectedHighAge >= 65 )) {
                    maleTotal += this.dma['total'][this.selectedYear].men_65_plus
                }

                return maleTotal
            }
            
        },
        femaleTotal() {
            var womanTotal = 0

            if (this.dma) {
                if ((this.selectedLowAge == 12) && (this.selectedHighAge >= 14)) {
                    womanTotal += this.dma['total'][this.selectedYear].women_12_to_14;
                }

                if ((this.selectedLowAge <= 15) && (this.selectedHighAge >= 17 )) {
                    womanTotal += this.dma['total'][this.selectedYear].women_15_to_17
                }

                if ((this.selectedLowAge <= 18) && (this.selectedHighAge >= 24 )) {
                    womanTotal += this.dma['total'][this.selectedYear].women_18_to_24
                }

                if ((this.selectedLowAge <= 25) && (this.selectedHighAge >= 34 )) {
                    womanTotal += this.dma['total'][this.selectedYear].women_25_to_34
                }

                if ((this.selectedLowAge <= 35) && (this.selectedHighAge >= 44 )) {
                    womanTotal += this.dma['total'][this.selectedYear].women_35_to_44
                }

                if ((this.selectedLowAge <= 45) && (this.selectedHighAge >= 49 )) {
                    womanTotal += this.dma['total'][this.selectedYear].women_45_to_49
                }

                if ((this.selectedLowAge <= 50) && (this.selectedHighAge >= 54 )) {
                    womanTotal += this.dma['total'][this.selectedYear].women_50_to_54
                }

                if ((this.selectedLowAge <= 55) && (this.selectedHighAge >= 64 )) {
                    womanTotal += this.dma['total'][this.selectedYear].women_55_to_64
                }

                if ((this.selectedLowAge <= 65) && (this.selectedHighAge >= 65 )) {
                    womanTotal += this.dma['total'][this.selectedYear].women_65_plus
                }

                return womanTotal
            }
        },
        incidence() {
            // this is the percent of the selected sample out of the national total

            if (!this.dma) {
                return 100
            }
            
            var nationalTotal = this.dma.total[this.selectedYear].men_total + this.dma.total[this.selectedYear].women_total
            
            var menTotal = this.maleTotal
            var womenTotal = this.femaleTotal
            
            if (this.selectedGender == "both") {
                var selectedTotal = menTotal + womenTotal
            } else if (this.selectedGender == "female") {
                var selectedTotal = womenTotal
            } else if (this.selectedGender == "male") {
                var selectedTotal = menTotal
            }

            console.log(selectedTotal, nationalTotal)
            return ((selectedTotal / nationalTotal) * 100).toFixed(1)

        }, 
        male_12_to_14() {
            if ((this.selectedLowAge == 12) && (this.selectedHighAge >= 14)) {
                var count = this.dma.total[this.selectedYear].men_12_to_14
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    return null
                } else if (this.selectedGender == "male") {
                    var c = count / totalMen
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        female_12_to_14() { 
            if ((this.selectedLowAge == 12) && (this.selectedHighAge >= 14)) {
                var count = this.dma.total[this.selectedYear].women_12_to_14
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    var c = count / totalWomen
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        total_12_to_14() { 
            if ((this.selectedLowAge == 12) && (this.selectedHighAge >= 14)) {
                var countWomen = this.dma.total[this.selectedYear].women_12_to_14
                var countMen = this.dma.total[this.selectedYear].men_12_to_14
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = (countWomen + countMen) / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                   return null
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        male_15_to_17() {
            if ((this.selectedLowAge <= 15) && (this.selectedHighAge >= 17)) {
                var count = this.dma.total[this.selectedYear].men_15_to_17
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    return null
                } else if (this.selectedGender == "male") {
                    var c = count / totalMen
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        female_15_to_17() { 
            if ((this.selectedLowAge <= 15) && (this.selectedHighAge >= 17)) {
                var count = this.dma.total[this.selectedYear].women_15_to_17
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    var c = count / totalWomen
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        total_15_to_17() { 
            if ((this.selectedLowAge <= 15) && (this.selectedHighAge >= 17)) {
                var countWomen = this.dma.total[this.selectedYear].women_15_to_17
                var countMen = this.dma.total[this.selectedYear].men_15_to_17
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = (countWomen + countMen) / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                   return null
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        male_18_to_24() {
            if ((this.selectedLowAge <= 18) && (this.selectedHighAge >= 24)) {
                var count = this.dma.total[this.selectedYear].men_18_to_24
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    return null
                } else if (this.selectedGender == "male") {
                    var c = count / totalMen
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        female_18_to_24() { 
            if ((this.selectedLowAge <= 18) && (this.selectedHighAge >= 24)) {
                var count = this.dma.total[this.selectedYear].women_18_to_24
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    var c = count / totalWomen
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        total_18_to_24() { 
            if ((this.selectedLowAge <= 18) && (this.selectedHighAge >= 24)) {
                var countWomen = this.dma.total[this.selectedYear].women_18_to_24
                var countMen = this.dma.total[this.selectedYear].men_18_to_24
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = (countWomen + countMen) / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                   return null
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        male_25_to_34() {
            if ((this.selectedLowAge <= 25) && (this.selectedHighAge >= 34)) {
                var count = this.dma.total[this.selectedYear].men_25_to_34
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    return null
                } else if (this.selectedGender == "male") {
                    var c = count / totalMen
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        female_25_to_34() { 
            if ((this.selectedLowAge <= 25) && (this.selectedHighAge >= 34)) {
                var count = this.dma.total[this.selectedYear].women_25_to_34
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    var c = count / totalWomen
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        total_25_to_34() { 
            if ((this.selectedLowAge <= 25) && (this.selectedHighAge >= 34)) {
                var countWomen = this.dma.total[this.selectedYear].women_25_to_34
                var countMen = this.dma.total[this.selectedYear].men_25_to_34
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = (countWomen + countMen) / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                   return null
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        male_35_to_44() {
            if ((this.selectedLowAge <= 35) && (this.selectedHighAge >= 44)) {
                var count = this.dma.total[this.selectedYear].men_35_to_44
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    return null
                } else if (this.selectedGender == "male") {
                    var c = count / totalMen
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        female_35_to_44() { 
            if ((this.selectedLowAge <= 35) && (this.selectedHighAge >= 44)) {
                var count = this.dma.total[this.selectedYear].women_35_to_44
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    var c = count / totalWomen
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        total_35_to_44() { 
            if ((this.selectedLowAge <= 35) && (this.selectedHighAge >= 44)) {
                var countWomen = this.dma.total[this.selectedYear].women_35_to_44
                var countMen = this.dma.total[this.selectedYear].men_35_to_44
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = (countWomen + countMen) / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                   return null
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        male_45_to_49() {
            if ((this.selectedLowAge <= 45) && (this.selectedHighAge >= 49)) {
                var count = this.dma.total[this.selectedYear].men_45_to_49
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    return null
                } else if (this.selectedGender == "male") {
                    var c = count / totalMen
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        female_45_to_49() { 
            if ((this.selectedLowAge <= 45) && (this.selectedHighAge >= 49)) {
                var count = this.dma.total[this.selectedYear].women_45_to_49
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    var c = count / totalWomen
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        total_45_to_49() { 
            if ((this.selectedLowAge <= 45) && (this.selectedHighAge >= 49)) {
                var countWomen = this.dma.total[this.selectedYear].women_45_to_49
                var countMen = this.dma.total[this.selectedYear].men_45_to_49
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = (countWomen + countMen) / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                   return null
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        male_50_to_54() {
            if ((this.selectedLowAge <= 50) && (this.selectedHighAge >= 54)) {
                var count = this.dma.total[this.selectedYear].men_50_to_54
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    return null
                } else if (this.selectedGender == "male") {
                    var c = count / totalMen
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        female_50_to_54() { 
            if ((this.selectedLowAge <= 50) && (this.selectedHighAge >= 54)) {
                var count = this.dma.total[this.selectedYear].women_50_to_54
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    var c = count / totalWomen
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        total_50_to_54() { 
            if ((this.selectedLowAge <= 50) && (this.selectedHighAge >= 54)) {
                var countWomen = this.dma.total[this.selectedYear].women_50_to_54
                var countMen = this.dma.total[this.selectedYear].men_50_to_54
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = (countWomen + countMen) / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                   return null
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        male_55_to_64() {
            if ((this.selectedLowAge <= 55) && (this.selectedHighAge >= 64)) {
                var count = this.dma.total[this.selectedYear].men_55_to_64
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    return null
                } else if (this.selectedGender == "male") {
                    var c = count / totalMen
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        female_55_to_64() { 
            if ((this.selectedLowAge <= 55) && (this.selectedHighAge >= 64)) {
                var count = this.dma.total[this.selectedYear].women_55_to_64
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    var c = count / totalWomen
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        total_55_to_64() { 
            if ((this.selectedLowAge <= 55) && (this.selectedHighAge >= 64)) {
                var countWomen = this.dma.total[this.selectedYear].women_55_to_64
                var countMen = this.dma.total[this.selectedYear].men_55_to_64
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = (countWomen + countMen) / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                   return null
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        male_65_plus() {
            if ((this.selectedLowAge <= 65) && (this.selectedHighAge >= 65)) {
                var count = this.dma.total[this.selectedYear].men_65_plus
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    return null
                } else if (this.selectedGender == "male") {
                    var c = count / totalMen
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        female_65_plus() { 
            if ((this.selectedLowAge <= 65) && (this.selectedHighAge >= 65)) {
                var count = this.dma.total[this.selectedYear].women_65_plus
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = count / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                    var c = count / totalWomen
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        },
        total_65_plus() { 
            if ((this.selectedLowAge <= 65) && (this.selectedHighAge >= 65)) {
                var countWomen = this.dma.total[this.selectedYear].women_65_plus
                var countMen = this.dma.total[this.selectedYear].men_65_plus
                var totalMen = this.maleTotal
                var totalWomen = this.femaleTotal

                if (this.selectedGender == "both") {
                    var c = (countWomen + countMen) / (totalMen + totalWomen)
                } else if (this.selectedGender == "female") {
                   return null
                } else if (this.selectedGender == "male") {
                    return null
                }

                return (this.sampleSize * c).toFixed(0)
            }
        }
    },
    watch: {
        dma(newVal, oldVal) {
            this.drawTable()
        },
        selectedYear(newVal, oldVal) {
            this.drawTable()
        },
        selectedGender(newVal, oldVal) {
            this.drawTable()
        },
        sampleSize(newVal, oldVal) {
            this.drawTable()
        },
        selectedLowAge(newVal, oldVal) {
            this.drawTable()
        },
        selectedHighAge(newVal, oldVal) {
            this.drawTable()
        }

    }
}
</script>
