<template>
<div class="columns">
    <div class="column is-10 is-offset-1">
        <table class="table" id="tblGenderAge">
            <thead>
                <tr>
                    <th>Age</th>
                    <th>Male</th>
                    <th>Male Percent</th>
                    <th>Female</th>
                    <th>Female Percent</th>
                    <th>Total</th>
                    <th>Total Percent</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>18-34</td>
                    <td>{{ (this.dma.total[this.selectedYear].men_18_to_24 + this.dma.total[this.selectedYear].men_25_to_34).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].men_18_to_24 + this.dma.total[this.selectedYear].men_25_to_34) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{ (this.dma.total[this.selectedYear].women_18_to_24 + this.dma.total[this.selectedYear].women_25_to_34).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].women_18_to_24 + this.dma.total[this.selectedYear].women_25_to_34) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{  (this.dma.total[this.selectedYear].men_18_to_24 + this.dma.total[this.selectedYear].men_25_to_34 + this.dma.total[this.selectedYear].women_18_to_24 + this.dma.total[this.selectedYear].women_25_to_34).toLocaleString() }}</td>
                    <td>{{  (100*((this.dma.total[this.selectedYear].men_18_to_24 + this.dma.total[this.selectedYear].men_25_to_34 + this.dma.total[this.selectedYear].women_18_to_24 + this.dma.total[this.selectedYear].women_25_to_34) / this.dma.total[this.selectedYear].total_hh)).toFixed(1) }}%</td>
                </tr>
                <tr>
                    <td>35-54</td>
                    <td>{{ (this.dma.total[this.selectedYear].men_35_to_54).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].men_35_to_54) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{ (this.dma.total[this.selectedYear].women_35_to_54).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].women_35_to_54) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{  (this.dma.total[this.selectedYear].men_35_to_54 + this.dma.total[this.selectedYear].women_35_to_54).toLocaleString() }}</td>
                    <td>{{  (100*((this.dma.total[this.selectedYear].men_35_to_54 + this.dma.total[this.selectedYear].women_35_to_54) / this.dma.total[this.selectedYear].total_hh)).toFixed(1) }}%</td>
                </tr>
                <tr>
                    <td>18-24</td>
                    <td>{{ (this.dma.total[this.selectedYear].men_18_to_24).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].men_18_to_24) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{ (this.dma.total[this.selectedYear].women_18_to_24).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].women_18_to_24) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{  (this.dma.total[this.selectedYear].men_18_to_24 + this.dma.total[this.selectedYear].women_18_to_24).toLocaleString() }}</td>
                    <td>{{  (100*((this.dma.total[this.selectedYear].men_18_to_24 + this.dma.total[this.selectedYear].women_18_to_24) / this.dma.total[this.selectedYear].total_hh)).toFixed(1) }}%</td>
                </tr>
                <tr>
                    <td>25-34</td>
                    <td>{{ (this.dma.total[this.selectedYear].men_25_to_34).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].men_25_to_34) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{ (this.dma.total[this.selectedYear].women_25_to_34).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].women_25_to_34) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{  (this.dma.total[this.selectedYear].men_25_to_34 + this.dma.total[this.selectedYear].women_25_to_34).toLocaleString() }}</td>
                    <td>{{  (100*((this.dma.total[this.selectedYear].men_25_to_34 + this.dma.total[this.selectedYear].women_25_to_34) / this.dma.total[this.selectedYear].total_hh)).toFixed(1) }}%</td>
                </tr>
                <tr>
                    <td>35-44</td>
                    <td>{{ (this.dma.total[this.selectedYear].men_35_to_44).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].men_35_to_44) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{ (this.dma.total[this.selectedYear].women_35_to_44).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].women_35_to_44) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{  (this.dma.total[this.selectedYear].men_35_to_44 + this.dma.total[this.selectedYear].women_35_to_44).toLocaleString() }}</td>
                    <td>{{  (100*((this.dma.total[this.selectedYear].men_35_to_44 + this.dma.total[this.selectedYear].women_35_to_44) / this.dma.total[this.selectedYear].total_hh)).toFixed(1) }}%</td>
                </tr>
                <tr>
                    <td>45-49</td>
                    <td>{{ (this.dma.total[this.selectedYear].men_45_to_49).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].men_45_to_49) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{ (this.dma.total[this.selectedYear].women_45_to_49).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].women_45_to_49) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{  (this.dma.total[this.selectedYear].men_45_to_49 + this.dma.total[this.selectedYear].women_45_to_49).toLocaleString() }}</td>
                    <td>{{  (100*((this.dma.total[this.selectedYear].men_45_to_49 + this.dma.total[this.selectedYear].women_45_to_49) / this.dma.total[this.selectedYear].total_hh)).toFixed(1) }}%</td>
                </tr>
                <tr>
                    <td>50-54</td>
                    <td>{{ (this.dma.total[this.selectedYear].men_50_to_54).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].men_50_to_54) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{ (this.dma.total[this.selectedYear].women_50_to_54).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].women_50_to_54) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{  (this.dma.total[this.selectedYear].men_50_to_54 + this.dma.total[this.selectedYear].women_50_to_54).toLocaleString() }}</td>
                    <td>{{  (100*((this.dma.total[this.selectedYear].men_50_to_54 + this.dma.total[this.selectedYear].women_50_to_54) / this.dma.total[this.selectedYear].total_hh)).toFixed(1) }}%</td>
                </tr>
                <tr>
                    <td>55-64</td>
                    <td>{{ (this.dma.total[this.selectedYear].men_55_to_64).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].men_55_to_64) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{ (this.dma.total[this.selectedYear].women_55_to_64).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].women_55_to_64) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{  (this.dma.total[this.selectedYear].men_55_to_64 + this.dma.total[this.selectedYear].women_55_to_64).toLocaleString() }}</td>
                    <td>{{  (100*((this.dma.total[this.selectedYear].men_55_to_64 + this.dma.total[this.selectedYear].women_55_to_64) / this.dma.total[this.selectedYear].total_hh)).toFixed(1) }}%</td>
                </tr>
                <tr>
                    <td>65+</td>
                    <td>{{ (this.dma.total[this.selectedYear].men_65_plus).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].men_65_plus) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{ (this.dma.total[this.selectedYear].women_65_plus).toLocaleString() }}</td>
                    <td>{{ (((this.dma.total[this.selectedYear].women_65_plus) / this.dma.total[this.selectedYear].total_hh)*100).toFixed(1) }}%</td>
                    <td>{{  (this.dma.total[this.selectedYear].men_65_plus + this.dma.total[this.selectedYear].women_65_plus).toLocaleString() }}</td>
                    <td>{{  (100*((this.dma.total[this.selectedYear].men_65_plus + this.dma.total[this.selectedYear].women_65_plus) / this.dma.total[this.selectedYear].total_hh)).toFixed(1) }}%</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
// jquery
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'
import PdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts";
import jsZip from "jszip"
window.JSZip = jsZip
PdfMake.vfs = pdfFonts.pdfMake.vfs
window.PdfMake = PdfMake
import '@/assets/css/dataTables.bulma.min.css'
import '@/assets/css/buttons.bulma.min.css'


export default {
    data: function() {
        return {
            table: null
        }
    },
    name: "table-gender-by-age",
    mounted() {
        this.drawTable()
    },
    methods: {
        drawTable() {
            if (this.table) {
                this.table.destroy()
            }
            this.$nextTick(() => {
                this.table = $("#tblGenderAge").DataTable({
                    searching: true,
                    ordering: true,
                    "paging": true,
                    dom: 'Blfrtip',
                    buttons: [
                        'copyHtml5',
                        {
                            extend: 'excelHtml5',
                            title: 'countyData'
                        },
                        {
                            extend: 'csvHtml5',
                            title: 'countyData'
                        },
                    ],
                    "columnDefs": [
                        {"className": "text-center", "targets": "_all"}
                    ]
                })
            })
        }
    },
    props: ['dma', 'selectedYear', 'applyWeight', 'hispanicAcculturationRate'],
    watch: {
        dma(newVal, oldVal) {
            this.drawTable()
        }
    }
}
</script>

<style scoped>
</style>