import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/404.vue'

// Account Views
import LoginView from '../views/AccountViews/Login.vue'
import ProfileView from '../views/AccountViews/Profile.vue'

// Password Views
import ChangePassword from '../views/PasswordReset/ChangePassword.vue'
import ResetPassword from '../views/PasswordReset/ResetPassword.vue'
import ResetSent from '../views/PasswordReset/ResetSent.vue'
import PasswordResetConfirm from '../views/PasswordReset/PasswordResetConfirm.vue'

// Internal Tools
import DMA from '../views/InternalTools/DMA.vue'
import ActiveProjects from "../views/ActiveProjects/ActiveProjects.vue"
import SampleCalculator from "../views/InternalTools/SampleCalculator.vue"
import Translator from '../views/InternalTools/Translator.vue'
import TranslatorFile from '../views/InternalTools/TranslatorFile.vue'
import TranslatorSingle from '../views/InternalTools/TranslatorSingle.vue'
import TranslatorNew from '../views/InternalTools/TranslatorNew.vue'


const routes = [
  { path: '/404', component: NotFound },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // ACCOUNT VIEWS
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      requireLogin: true
    }
  },
  // PASSWORD VIEWS
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/passwordreset/:uid/:token',
    component: PasswordResetConfirm,
  },
  {
    path: '/resetsent',
    name: 'ResetSent',
    component: ResetSent
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: ChangePassword,
    meta: {
      requireLogin: true
    }
  },
  // Internal Tools
  {
    path: '/dma',
    name: 'dma',
    component: DMA,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/activeprojects',
    name: 'activeprojects',
    component: ActiveProjects,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/samplecalculator',
    name: 'samplecalculator',
    component: SampleCalculator,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/translator',
    name: 'translator',
    component: Translator,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/translator/file',
    name: 'translatorfile',
    component: TranslatorFile,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/translator/single',
    name: 'translatorsingle',
    component: TranslatorSingle,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/translator/new',
    name: 'translatornew',
    component: TranslatorNew,
    meta: {
      requireLogin: true
    }
  },
  { path: '/:pathMatch(.*)*', redirect: '/404' }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'login', query: { to: to.path }})
  } else {
    next()
  }
})

export default router
