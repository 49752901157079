<template>
<div id="sidebar-wrapper">
    <ul class="sidebar-nav">
        <li data-toggle="tooltip" data-placement="right" title="Translate Home"><a href="/translator"><i class="fa fa-home"></i></a></li>
        <li data-toggle="tooltip" data-placement="right" title="Translate File"><a href="/translator/file"><i class="fas fa-language"></i></a></li>
        <li data-toggle="tooltip" data-placement="right" title="Translate Single Text"><a href="/translator/single"><i class="fas fa-font"></i></a></li>
        <li data-toggle="tooltip" data-placement="right" title="Upload New Translations"><a href="/translator/new"><i class="fas fa-file-upload"></i></a></li>
        <li data-toggle="tooltip" data-placement="right" title="Export Spanish Dictionary">
            <a v-on:click="this.exportSpanishDictionary()"><i class="fas fa-file-download"></i></a>
        </li>
    </ul>
</div>
</template>

<style scoped>
.tooltip {
    position: fixed;
}

/* SIDE BAR */
#sidebar-wrapper {
    left: 0px;
    z-index: 1000;
    position: fixed;
    width: 50px;
    height: 100%;
    overflow-y: auto;
    background: #111;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 50px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    text-indent: 10px;
    line-height: 40px;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #999999;
}

.sidebar-nav li a:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active, .sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav>.sidebar-brand a {
    color: #999999;
}

.sidebar-nav>.sidebar-brand a:hover {
    color: #fff;
    background: none;
}
</style>

<script>
import { toast } from 'bulma-toast'

export default {
    name: "sidebar",
    components: { toast },
    data() {
        return {

        }
    },
    methods: {
        exportSpanishDictionary() {
            this.$store.commit("setIsLoading", true)
            toast({
                message: "Exporting Spanish Translate Dictionary",
                type: "is-primary",
                dismissible: true
            })
            this.$store.commit("setIsLoading", false)
        }
    }
}
</script>
