<template>
<div class="columns">
    <div class="column is-8 is-offset-1">
        <table class="table" id="tblCountyData">
            <thead>
                <tr>
                    <th>County Name {{this.applyWeight}}</th>
                    <th>State</th>
                    <th>Metro</th>
                    <th>TV HH</th>
                    <th>Male</th>
                    <th>Female</th>
                    <th>AA HH</th>
                    <th v-if="this.applyWeight">Hispanic HH*</th>
                    <th v-else>Hispanic HH</th>
                    <th>Total HH</th>
                    <th>Zipcodes</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="county in this.dma.counties[this.selectedYear]" v-bind:key="county.id">
                    <td>{{ county.name }}</td>
                    <td>{{ county.state.short }}</td>
                    <td><span v-if="county.metro">M</span> <span v-else></span></td>
                    <td v-if="county.total_hh">{{ county.total_hh.toLocaleString() }}</td><td v-else></td>
                    <td v-if="county.men_total">{{ county.men_total.toLocaleString() }}</td><td v-else></td>
                    <td v-if="county.women_total">{{ county.women_total.toLocaleString() }}</td><td v-else></td>
                    <td v-if="county.black_tv_hh">{{ county.black_tv_hh.toLocaleString() }}</td><td v-else></td>
                    <td v-if="this.applyWeight">{{ (this.hispanicAcculturationRate * county.hispanic_tv_hh).toLocaleString() }}</td><td v-else>{{ county.hispanic_tv_hh.toLocaleString() }}</td>
                    <td v-if="county.total_tv_hh">{{ county.total_tv_hh.toLocaleString()}}</td><td v-else></td>
                    <td>{{ county.zipcode_list.join(",") }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
// jquery
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'
import PdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts";
import jsZip from "jszip"
window.JSZip = jsZip
PdfMake.vfs = pdfFonts.pdfMake.vfs
window.PdfMake = PdfMake
import '@/assets/css/dataTables.bulma.min.css'
import '@/assets/css/buttons.bulma.min.css'


export default {
    data: function() {
        return {
            table: null
        }
    },
    name: "table-county",
    mounted() {
        this.drawTable()
    },
    methods: {
        drawTable() {
            if (this.table) {
                this.table.destroy()
            }
            this.$nextTick(() => {
                this.table = $("#tblCountyData").DataTable({
                    searching: true,
                    ordering: true,
                    paging: true,
                    dom: "Bfrtip",
                    buttons: [
                    'copyHtml5',
                        {
                            extend: 'excelHtml5',
                            title: 'GenderByAgeTotals'
                        },
                        {
                            extend: 'csvHtml5',
                            title: 'GenderByAgeTotals'
                        },
                    ]
                })
                console.log("table", this.table)
            })
        }
    },
    props: [
        'dma', 
        'selectedYear', 
        'applyWeight', 
        'hispanicAcculturationRate'
    ],
    watch: {
        dma(newVal, oldVal) {
            this.drawTable()
        }
    }
}
</script>

<style scoped>
</style>