<template>
<div class="columns">
    <div class="column is-10 is-offset-1">
        <table class="table" id="tblGenderAgeCounty">
            <thead>
                <tr>
                    <th>County Name</th>
                    <th>Male 12-14</th>
                    <th>Male 15-17</th>
                    <th>Male 18-20</th>
                    <th>Male 21-24</th>
                    <th>Male 25-34</th>
                    <th>Male 35-44</th>
                    <th>Male 45-49</th>
                    <th>Male 50-54</th>
                    <th>Male 55-64</th>
                    <th>Male 65+</th>
                    <th>Female 12-14</th>
                    <th>Female 15-17</th>
                    <th>Female 18-20</th>
                    <th>Female 21-24</th>
                    <th>Female 25-34</th>
                    <th>Female 35-44</th>
                    <th>Female 45-49</th>
                    <th>Female 50-54</th>
                    <th>Female 55-64</th>
                    <th>Female 65+</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="county in this.dma.counties[this.selectedYear]" v-bind:key="county.name">
                    <td>{{ county.name }}</td>
                    <td> {{ county.men_12_to_14.toLocaleString() }}</td>
                    <td> {{ county.men_15_to_17.toLocaleString() }}</td>
                    <td> {{ county.men_18_to_20.toLocaleString() }}</td>
                    <td> {{ county.men_21_to_24.toLocaleString() }}</td>
                    <td> {{ county.men_25_to_34.toLocaleString() }}</td>
                    <td> {{ county.men_35_to_44.toLocaleString() }}</td>
                    <td> {{ county.men_45_to_49.toLocaleString() }}</td>
                    <td> {{ county.men_50_to_54.toLocaleString() }}</td>
                    <td> {{ county.men_55_to_64.toLocaleString() }}</td>
                    <td> {{ county.men_65_plus.toLocaleString() }}</td>
                    <td> {{ county.women_12_to_14.toLocaleString() }}</td>
                    <td> {{ county.women_15_to_17.toLocaleString() }}</td>
                    <td> {{ county.women_18_to_20.toLocaleString() }}</td>
                    <td> {{ county.women_21_to_24.toLocaleString() }}</td>
                    <td> {{ county.women_25_to_34.toLocaleString() }}</td>
                    <td> {{ county.women_35_to_44.toLocaleString() }}</td>
                    <td> {{ county.women_45_to_49.toLocaleString() }}</td>
                    <td> {{ county.women_50_to_54.toLocaleString() }}</td>
                    <td> {{ county.women_55_to_64.toLocaleString() }}</td>
                    <td> {{ county.women_65_plus.toLocaleString() }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
// jquery
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'
import PdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts";
import jsZip from "jszip"
window.JSZip = jsZip
PdfMake.vfs = pdfFonts.pdfMake.vfs
window.PdfMake = PdfMake
import '@/assets/css/dataTables.bulma.min.css'
import '@/assets/css/buttons.bulma.min.css'


export default {
    data: function() {
        return {
            table: null
        }
    },
    name: "table-gender-by-age-by-county",
    mounted() {
        this.drawTable()
    },
    methods: {
        drawTable() {
            if (this.table) {
                this.table.destroy()
            }

            this.$nextTick(() => {
                this.table = $("#tblGenderAgeCounty").DataTable({
                    searching: false,
                    ordering: true,
                    paging: true,
                    dom: "Brtip",
                    buttons: [
                    'copyHtml5',
                        {
                            extend: 'excelHtml5',
                            title: 'GenderByAgeTotals'
                        },
                        {
                            extend: 'csvHtml5',
                            title: 'GenderByAgeTotals'
                        },
                    ],
                    "columnDefs": [
                        {"className": "text-center", "targets": "_all"}
                    ]
                })
            })
        }
    },
    props: [
        'dma', 
        'selectedYear', 
        'applyWeight', 
        'hispanicAcculturationRate'
    ],
    watch: {
        dma(newVal, oldVal) {
            this.drawTable()
        }
    }
}
</script>

<style scoped>
</style>