<template>
    <Line 
        :chart-options="options"
        :chart-data="chartData"
        :height="height"
    />
</template>

<script>
import { Line } from 'vue-chartjs'
//import 'chart.js/auto'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    Plugin,
    Filler
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    Filler
)

export default {
    name: "ProjectChart",
    components: {
        Line
    },
    data: function() {
        return {
            height: 300,
        }
    }, 
    computed: {
        chartData() {
            return {
                labels: this.labels,
                datasets: [
                    {
                        label: this.dataLabel,
                        data: this.datapoints,
                        fill: true,
                        borderColor: "rgba(0, 0, 102, 0.8)",
                        backgroundColor: "rgba(0, 0, 102, 0.5)",
                        pointHitRadius: 10,
                        pointHoverRadius: 20,
                    }
                ]
            }
        },
        options() {
            return {
                animation: {
                    duration: 300
                },
                legend: {
                    labels: {
                        boxWidth: 0,
                        fontSize: 22
                    },
                    onClick: (e) => e.stopPropagation()
                },
                scales: {
                    yAxis: {
                        beginAtZero: true
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        enabled: true,
                        bodyFontSize: 30,
                        callbacks: {
                            label: function(context) {
                                return "Valids: " + context.parsed.y
                            }
                        }
                    }
                }
            }
        }
    },
    props: [
        'project', 'labels', 'datapoints', "dataLabel"
    ]
}
</script>
