<template>
    <div id="page-passwordresetconfirm">
        <div class="columns">
            <div class="column is-6 is-offset-3">
                <h1 class="title">Reset your password</h1>
                <p class="">You can reset your password using the form below. Please remember your new password needs to be at least 8 characters and not be a common password</p>
                <br />
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label class=''>New Password</label>
                        <div class="control">
                            <input type="password" class="input" v-model="new_password" placeholder="password">
                        </div>
                    </div>

                    <div class="field">
                        <label class="">Repeat New Password</label>
                        <div class="control">
                            <input type="password" class="input" v-model="re_new_password" placeholder="repeat password">
                        </div>
                    </div>

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button id="btn-login" class="button is-success">Update Password</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {toast} from 'bulma-toast'

export default {
    name: "PasswordResetConfirm",
    data() {
        return {
            new_password: '',
            re_new_password: '',
            uid: this.$route.params.uid,
            token: this.$route.params.token,
            errors: []
        }
    },
    mounted() {
        document.title = "Password Reset | SG Tools"
    },
    methods: {
        async submitForm() {
            this.$store.commit("setIsLoading", true)
            const formData = {
                uid: this.uid,
                token: this.token,
                new_password: this.new_password,
                re_new_password: this.re_new_password
            }

            this.errors = []
            await axios.post('users/reset_password_confirm/', formData)
                .then(response => {
                    toast({
                        message: "Password successfully updated",
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-center'
                    })
                    this.$store.commit("setIsLoading", false)
                    this.$router.push({name: "login"})
                })
                .catch(error =>{
                    console.log(JSON.stringify(error))
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }
                        toast({
                            message: JSON.stringify(error.message),
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'top-center'
                        })
                        
                        this.$store.commit("setIsLoading", false)
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                        toast({
                            message: JSON.stringify(error),
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'top-center'
                        })
                        
                        this.$store.commit("setIsLoading", false)
                    }
                })

        }
    }
}
</script>

<style scoped>
</style>
