<template>
  <div id="wrapper" class="full-height">
    <Navbar />
    <section class="is-loading-bar has-text-centered" v-bind:class="{'is-loading': $store.state.isLoading }">
      <div class="lds-dual-ring"></div>
    </section>

    <router-view :key="$route.fullPath" />
    <br />
    <Footer />
  </div>
</template>

<style lang="scss">
@import '../node_modules/bulma';

html, body {
  height: 100%;
  width: 100%;
  font-family: Roboto;
}

#wrapper {
  background-color: #000;
}

.full-height {
  height: 100%;
}

.footer {
  background-color: #000;
}

.button.button.is-success {
  background-color: #00bae0;
  color: #fff;
}

footer > p {
  background-color: #000;
  color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

// loading bar
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-loading-bar {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  &.is-loading {
    height: 80px;
  }
}
</style>

<script>
import axios from 'axios'
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'

export default {
  data() {
    return {
      showMobileMenu: false,
    }
  },
  components: {
    Footer,
    Navbar
  },
  beforeCreate() {
    // calls the mutations
    this.$store.commit('initializeState')
    const token = this.$store.state.token

    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }
  }
}
</script>

