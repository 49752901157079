<template>
    <div class="page-profile">
        <div class="columns">
            <div class="column is-12">
                <h1 class="title has-text-centered">My Profile</h1>
            </div>
        </div>
        <div id="user-div" class="columns">
            <div class="column is-6 is-offset-4">
                <section v-if="isEditing === false">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td><strong>First Name</strong></td>
                                <td>{{ profile.first_name }}</td>
                            </tr>
                            <tr>
                                <td><strong>Last Name</strong></td>
                                <td>{{ profile.last_name }}</td>
                            </tr>
                            <tr>
                                <td><strong>Username</strong></td>
                                <td>{{ profile.username }}</td>
                            </tr>
                            <tr>
                                <td><strong>Email</strong></td>
                                <td>{{ profile.email }}</td>
                            </tr>
                            <tr>
                                <td><strong>Last Login</strong></td>
                                <td>{{ this.formatDateString(profile.last_login) }}</td>
                            </tr>
                            <tr>
                                <td><strong>Account Created</strong></td>
                                <td>{{ this.formatDateString(profile.date_joined) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <section v-else>
                    <form @submit.prevent="submitForm">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td><strong>First Name</strong></td>
                                    <td>
                                        <div class="field">
                                            <div class="control">
                                                <input type="text" class="input" v-model="profile.first_name">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Last Name</strong></td>
                                    <td>
                                        <div class="field">
                                            <div class="control">
                                                <input type="text" class="input" v-model="profile.last_name">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Username</strong></td>
                                    <td>{{ profile.username }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Email</strong></td>
                                    <td>
                                        <div class="field">
                                            <div class="control">
                                                <input type="text" class="input" v-model="profile.email">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Last Login</strong></td>
                                    <td>{{ profile.last_login }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Account Created</strong></td>
                                    <td>{{ profile.date_joined }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="notification is-danger" v-if="errors.length">
                            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                        </div>
                        
                        <div class="field">
                            <div class="control">
                                <button id="btn-save" class="button is-danger">Save Changes</button>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
        <div id="button-div" class="columns">
            <div class="column is-4 is-offset-4">
                <button class="button is-success" v-on:click="toggleEdit" v-if="isEditing === false">Edit</button>
                <button class="button" style="background-color: gray;" v-on:click="toggleEdit" v-if="isEditing">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: "Profile",
    data() {
        return {
            profile: {},
            isEditing: false,
            errors: []
        }
    },
    methods: {
        async getProfile() {
            this.$store.commit("setIsLoading", true)
            await axios
                .get('users/me/')
                .then(response =>{
                    this.profile = response.data
                    this.$store.commit("setIsLoading", false)
                    console.log(this.profile);
                })
                .catch(error =>{
                    console.log(error)
                    this.$store.commit("setIsLoading", false)
                })
        },
        toggleEdit() {
            this.isEditing = !this.isEditing
        },
        formatDateString(ds) {
            var date = new Date(ds)
            return date.toLocaleString()
        },
        async submitForm() {
            this.$store.commit("setIsLoading", true)
            const formData = {
                'first_name': this.profile.first_name,
                'last_name': this.profile.last_name,
                'email': this.profile.email
            }

            await axios
                .patch("users/me/", formData)
                .then(response => {
                    toast({
                        message: "Profile successfully updated",
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-center'
                    })
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
                    toast({
                        message: "Error updating profile. " + JSON.stringify(error),
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-center'
                    })
                })
            this.isEditing = false
            this.$store.commit("setIsLoading", false)
        }
    },
    mounted() {
        document.title = "Profile | SG Tools"
        this.getProfile()
    }
}
</script>

<style>
#wrapper {
    background-color: #fff;
}

footer {
    background-color: #fff !important;
}
</style>
