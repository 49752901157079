<template>
    <div class="page-translator">
        <sidebar />
        <div id="columns has-text-centered">
            <div class="column is-12 has-text-centered">
                <h1 class="title">Translate Single Text</h1>
            </div>
        </div>

        <div class="columns has-text-centered">
            <div class="column is-4 is-offset-4">
                <label class="label">Language to Translate Into</label>
                <select class="input" v-model="this.selectedLanguage">
                    <option v-for="language in this.languages" :key="language.code">{{ language.language }}</option>
                </select>
            </div>
        </div>

        <div id="columns has-text-centered">
            <div class="column is-4 is-offset-4">
                <p>Enter the english text in the box below. Then press <strong>Translate</strong></p>
                <textarea class="input" v-model="this.text" style="height: 400px"></textarea>
            </div>
        </div>

        <div id="columns has-text-centered">
            <div class="column is-4 is-offset-4">
                <button class="button is-primary" v-on:click="this.translate()">Translate</button>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
import Sidebar from "@/components/Translator/Sidebar.vue"
import axios from 'axios'
import {toast} from 'bulma-toast'

export default {
    name: 'TranslatorSingle',
    components: {
        Sidebar,
        toast
    },
    data() {
        return {
            languages: [{language: "Spanish", code: "spanish"}],
            selectedLanguage: "Spanish",
            text: '',
        }
    },
    mounted() {
        document.title = "Translate Single Text | SG Tools"
    },
    methods: {
        async translate() {
            let formData = new FormData()
            formData.append("english_text", this.text)

            this.$store.commit('setIsLoading', true)

            await axios
                .post(
                    'translator/single/',
                    formData
                )
                .then(response => {
                    console.log(response)

                    if (response.data.translation == "") {
                        toast({
                            message: "Translation not found",
                            type: "is-danger",
                            dismissible: true
                        })
                    } else {
                        this.text = response.data.translation
                        toast({
                            message: "Translation found",
                            type: "is-success",
                            dismissible: true
                        })
                    }
                    this.$store.commit('setIsLoading', false)
                })
        }
    }
}
</script>
