<template>
    <div class="pagedma-tool">
        <div class="columns">
            <div class="column is-12 has-text-centered">
                <h1 class="title">DMA Tool</h1>
            </div>
        </div>
        <div class="columns">
            <div class="column is-8 is-desktop" style="margin-left: 20px">
                <DMAMap 
                    :dmas="this.dma_dict"
                    :selectedDMA="this.selectedDMA"
                    @choseDMA="choseDMA" />
            </div>

            <div class="column">
                <v-select v-model="selectedDMA"
                    placeholder="Select a DMA"
                    id="dma_dropdown" 
                    :options="this.dmas"
                    label="display"
                    key="code"
                    @option:selected="getSelectedDMA($event)"
                >
                </v-select>

                <table class="table">
                    <tr>
                        <td class="tblLayout">Hispanic Acculturation Rate</td>
                        <td class="tblLayout"><input type="text" class="input" v-model="this.hispanicAcculturationRate" /></td>
                    </tr>
                    <tr>
                        <td class="tblLayout">Apply Weight</td>
                        <td class="tblLayout"><input type="checkbox" v-model="this.applyWeight" /></td>
                    </tr>
                    <tr>
                        <td class="tblLayout">Sample Size</td>
                        <td class="tblLayout"><input type="text" class="input" v-model="this.sampleSize" /></td>
                    </tr>
                </table>

                <div class="column is-12" v-if="this.dma">
                    <div class="column">
                        <div class="select">
                            <select id="yearSelect" v-model="this.selectedYear" @change="changeYear($event)">
                                <option v-for="( year, idx ) in this.dma.years" :key="idx">
                                    {{ year }}
                                </option>
                            </select>
                        </div>  
                    </div>
                    <div class="columns">
                        <div class="column is-12">
                            <h1 class="title">{{ this.dma.dma.name }}</h1>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-12">
                            <table class="table">
                                <tr>
                                    <td class="tblLayout">DMA</td>
                                    <td class="tblLayout">{{ this.dma.dma.code }}</td>
                                </tr>
                                <tr>
                                    <td class="tblLayout">DMA Rank</td>
                                    <td class="tblLayout">{{ this.dma.dma.rank }}</td>
                                </tr>
                                <tr>
                                    <td class="tblLayout">Zipcode Prefixes</td>
                                    <td class="tblLayout">{{ this.selectedDMA.zipcode_prefixes.join(",") }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="this.dma">
            <!-- Overall Ethnicity Table -->
            <div class="columns">
                <div class="column is-8 is-offset-1">
                    <h1 class="subtitle">Overall DMA Ethnicities</h1>
                </div>
            </div>

            <table-overall-ethnicity :dma="this.dma" :selectedYear="this.selectedYear" :sampleSize="this.sampleSize" />

            <!-- Nielsen County Data Table -->
            <div class="columns">
                <div class="column is-8 is-offset-1">
                    <h1 class="subtitle">Nielsen County Data</h1>
                </div>
            </div>

            <table-county :selectedYear="this.selectedYear" :dma="this.dma" :applyWeight="this.applyWeight" :hispanicAcculturationRate="this.hispanicAcculturationRate" />
            
            <div class="columns">
                <div class="column is-8 is-offset-1">
                    <p v-if="this.applyWeight">* Hispanic acculturation down weighting applied.</p>
                </div>
            </div>

            <!-- Age Data Table -->
            <div class="columns">
                <div class="column is-8 is-offset-1">
                    <h1 class="subtitle">Gender by Age Data</h1>
                </div>
            </div>

            <table-gender-by-age :dma="this.dma" :selectedYear="this.selectedYear" />

            <!-- Gender by Age by County Data Table -->
            <div class="columns">
                <div class="column is-8 is-offset-1">
                    <h1 class="subtitle">Gender by Age by County</h1>
                </div>
            </div>

            <table-gender-by-age-by-county :dma="this.dma" :selectedYear="this.selectedYear" />

            <!-- Station Table -->
            <div class="columns">
                <div class="column is-8 is-offset-1">
                    <h1 class="subtitle">Stations</h1>
                </div>
            </div>

            <table-station :dma="this.selectedDMA" />

        </template>
    </div>
</template>

<style scoped>
 .pagedma-tool {
    padding-bottom: 25px;
    max-width: 95%;
 }
</style>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import TableOverallEthnicity from '@/components/DMA/TableOverallEthnicity.vue'
import TableCounty from '@/components/DMA/TableCounty.vue'
import TableGenderByAge from '../../components/DMA/TableGenderByAge.vue'
import TableGenderByAgeByCounty from '../../components/DMA/TableGenderByAgeByCounty.vue'
import DMAMap from '../../components/DMA/DMAMap.vue'
import TableStation from '../../components/DMA/TableStation.vue'

// VueSelect
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

// jquery
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'
import PdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts";
import jsZip from "jszip"
window.JSZip = jsZip
PdfMake.vfs = pdfFonts.pdfMake.vfs
window.PdfMake = PdfMake


export default {
    name: "DMA",
    data() {
        return {
            username: '',
            password: '',
            errors: [],
            timesFailed: 0,
            dmas: [],
            selectedDMA: null,
            selectedYear: null,
            dma: null,
            hispanicAcculturationRate: 0.65,
            applyWeight: true,
            sampleSize: 400,
            dma_dict: {}
        }
    },
    components: {
        vSelect,
        TableOverallEthnicity,
        TableCounty,
        TableGenderByAge,
        TableGenderByAgeByCounty,
        DMAMap,
        TableStation
    },
    mounted() {
        document.title = "DMA Tool | SG Tools"
        this.$store.commit("setIsLoading", false)
        this.getAllDMAs()

    },
    methods: {
        async getAllDMAs() {
            try {
                this.$store.commit("setIsLoading", true)
                toast({
                    message: "Loading DMA Data. Please wait...",
                    type: 'is-danger',
                    dismissible: true
                })
                let response = await axios.get('dma/')
                const total = response.data.count
                const perPage = response.data.results.length
                const totalPages = Math.ceil(total / perPage)
                const promiseArray = []

                for (let i=0; i < (totalPages); i++) {
                    let query = 'dma/?limit=20&offset=' + (20*i) + '&page=' + (i+1);
                    console.log(query)
                    promiseArray.push(axios.get(query))
                };

                console.log(promiseArray);

                // promise.all alls you to make multiple axios requests at the same time
                // It returns an array of the results
                let resolvedPromises = await Promise.all(promiseArray)

                let dmas = resolvedPromises.reduce(function(arr, row){
                    return arr.concat(row.data.results)
                }, [])

                dmas = dmas.sort((a, b) => (a.rank > b.rank) ? 1 : -1)

                this.dmas = dmas
                var dma_dict = {}
                for (let i=0; i < this.dmas.length; i++) {
                    dma_dict[this.dmas[i].code] = {
                        "name": this.dmas[i].name,
                        "rank": this.dmas[i].rank
                    }
                }
                this.dma_dict = dma_dict
                this.$store.commit("setIsLoading", false)
                toast({
                    message: "Ready!",
                    type: 'is-success'
                })
            } catch  (err) {
                console.log('[!] ERROR: Something went wront')
                console.log(err)
                toast({
                    message: "[!] ERROR: Something went wrong getting all DMAs" + "\n" + err,
                    type: 'is-danger',
                    dismissible: true
                })
            }
        },
        async getSelectedDMA(event) {
            //this.$store.commit('setIsLoading', true)
            try {
                await axios
                    .get('data/' + event.code + "/")
                    .then(response => {
                        this.dma = response.data
                        console.log(this.dma)
                        this.selectedYear = this.dma.years[0]
                        this.$store.commit('setIsLoading', false)
                    })
            } catch (err) {
                toast({
                    message: "[!] ERROR: Something went wrong getting selected DMAs" + "\n" + err,
                    type: 'is-danger',
                    dismissible: true
                })
            }
        },
        changeYear(event) {
            console.log(event)
            console.log(this.selectedYear)
        },
        choseDMA(dma_id) {
            for (var idx in this.dmas) {
                let dma = this.dmas[idx]
                if (dma.code == dma_id) {
                    this.selectedDMA = dma
                    this.getSelectedDMA(dma)
                    console.log(dma)
                    break
                }
            }
        }
    }
}
</script>
