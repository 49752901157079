<template>
  <div class="home full-height">
    <section class="section hero is-medium is-dark mb-6">
      <div class="hero-body has-text-centered">
        <p class="title mb-6">
          <image src="images/SmithGeigerGroup.png" />
        </p>
        <p class="mb-6">measure. <span class="blue-color">understand</span>. influence.</p>
      </div>

      <div class="has-text-centered">
        <button class="button blue-button" @click="$router.push('login')">Log in</button>
      </div>
    </section>  
  </div>
</template>

<style scoped>
.home {
  background-color: #000 !important;
}

.section {
  background-color: #000;
}

.hero .hero-body {
  background-color: #000;
  padding-bottom: 0px;
}

.blue-color {
  color: rgb(0, 186, 224);
}

.blue-button {
  background-color: rgb(0, 186, 224) !important;
  border: 0px;
  color: white;
}
</style>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  mounted() {
    document.title = "SG Tools"
    if (this.$store.state.isAuthenticated === true) {
        const toPath = this.$route.query.to || '/profile'
        this.$router.push(toPath)
    }
  }
}
</script>
