<template>
    <div class="page-resetsent">
        <div class="columns">
            <div class="column is-6 is-offset-3">
                <h1 class="title">Password Reset Confirmation</h1>

                <p class="">Check your inbox for a link to reset your password. If an email doesn't appear within a few minutes, check your spam folder.</p>
                <br />
                <router-link to="/login"><button class="button is-success">Login</button></router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ResetSent",
    data() {
        return {}
    },
    mounted() {
        document.title = "Password Resent Sent | SG Tools"
    },
}
</script>

<style scoped>

</style>
