<template>
    <nav class="navbar is-dark">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <img src="/images/header_logo.svg" width="300" />
        </router-link>

        <a class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu" @click="showMobileMenu = !showMobileMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu" id="navbar-menu" v-bind:class="{'is-active': showMobileMenu}" >
        <div class="navbar-end">
        <div class="navbar-item">
          <template v-if="$store.state.isAuthenticated">
              <div class="navbar-item has-dropdown is-hoverable">
                  <a class="navbar-link">
                      Account
                  </a>
                  <div class="navbar-dropdown">
                      <router-link to="/profile" class="navbar-item has-text-white" @click="toggle">My Profile</router-link>
                      <router-link to="/changepassword" class="navbar-item has-text-white" @click="toggle">Change Password</router-link>
                      <a class="navbar-item has-text-white" @click="logout">Log out</a>
                  </div>
              </div>
          </template>
          <template v-else>
              <router-link to="/login" class="button is-success">Log in</router-link>
          </template>
        </div>
        <div class="navbar-item">
          <template v-if="$store.state.isAuthenticated">
            <div class="navbar-item has-dropdown is-hoverable">
                  <a class="navbar-link">
                      Internal Tools
                  </a>
                  <div class="navbar-dropdown">
                      <router-link to="/dma" class="navbar-item has-text-white" @click="toggle">DMA Tool</router-link>
                      <router-link to="/samplecalculator" class="navbar-item has-text-white" @click="toggle">Sample Calculator</router-link>
                      <router-link to="/translator" class="navbar-item has-text-white" @click="toggle">Translator</router-link>
                  </div>
              </div>
            <router-link to="/activeprojects" class="navbar-item">Active Projects</router-link>
          </template>
        </div>
        </div>
      </div>
    </nav>
</template>

<script>
import axios from 'axios'

export default {
    name: "Navbar",
    data() {
        return {
            showMobileMenu: false
        }
    },
    methods: {
        logout() {
            this.showMobileMenu = false
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            localStorage.removeItem("username")
            this.$store.commit('removeToken')
            this.$router.push('/')
        },
        toggle() {
            this.showMobileMenu = !this.showMobileMenu
        }
    }
}

</script>

<style scoped>
.navbar.is-dark {
  background-color: #000000!important;
}

#navbar-menu > div > a.navbar-item:hover {
  background-color: #00bae0!important;
}

.navbar-menu {
  background-color: #000;
}

.navbar-item {
  background-color: #000;
}

.navbar-item, .navbar-link {
  color: white !important;
}

navbar-item .router-link-active {
  background-color: #00bae0 !important;
}

#navbar-menu > div.navbar-end > div > div > div > a:hover {
  background-color: #00bae0 !important;
}

#navbar-menu > div.navbar-end > div > div > div > a:active {
  background-color: #00bae0 !important;
}


a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  border-bottom: 3px solid #00bae0;
  background-color: hsl(0,0%,4%);
}


</style>