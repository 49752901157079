<template>
    <div class="page-changepassword">
        <div class="columns">
            <div class="column is-6 is-offset-3">
                <h1 class="title">Change your password</h1>
                <p>You can change your password using the form below. Please remember your new password needs to be at least 8 characters and not be a common password</p>
                <br />
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label class=''>Current Password</label>
                        <div class="control">
                            <input type="password" class="input" v-model="current_password">
                        </div>
                    </div>

                    <div class="field">
                        <label class=''>New Password</label>
                        <div class="control">
                            <input type="password" class="input" v-model="new_password">
                        </div>
                    </div>

                    <div class="field">
                        <label class="">Repeat New Password</label>
                        <div class="control">
                            <input type="password" class="input" v-model="re_new_password">
                        </div>
                    </div>

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button id="btn-login" class="button is-success">Update Password</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'ChangePassword',
    data() {
        return {
            new_password: '',
            re_new_password: '',
            current_password: '',
            errors: []
        }
    },
    mounted() {
        document.title = "Change Password | SG Tools"
    },
    methods: {
        async submitForm() {
            this.errors = []

            const formData = {
                new_password: this.new_password,
                re_new_password: this.re_new_password,
                current_password: this.current_password
            }

            this.$store.commit('setIsLoading', true)

            await axios
                .post("users/set_password/", formData)
                .then(response => {
                    this.current_password = ''
                    this.new_password = ''
                    this.re_new_password = ''

                    toast({
                        message: "Successfully changed password!",
                        type: 'is-success',
                        dismissible: true,
                        duration: 2000,
                        pauseOnHover: true,
                        position: 'top-center'
                    })
                })
                .catch(error => {
                    this.current_password = ''
                    this.new_password = ''
                    this.re_new_password = ''

                    toast({
                        message: "Error changing password",
                        type: 'is-danger',
                        dismissible: true,
                        duration: 2000,
                        pauseOnHover: true,
                        position: 'top-center'
                    })

                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                    }
                })
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>
