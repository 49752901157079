<template>
    <div class="page-404">
        <div class="columns">
            <div class="column is-6 is-offset-3">
                <h1 class="title">Whoops!</h1>

                <p class="">That's not a URL we know about. Sorry about that.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotFound",
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
