<template>
<div class="columns">
    <div class="column is-5 is-offset-1">
        <table class="table" id="tblOverallEthnicities">
            <thead>
                <tr>
                    <th>DMA</th>
                    <th>African-American</th>
                    <th>Hispanic</th>
                    <th>Asian</th>
                    <th>Total TV HH</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Counts</td>
                    <td>{{ ((this.dma.total[this.selectedYear].black_tv_hh / this.dma.total[this.selectedYear].total_tv_hh) * this.sampleSize).toFixed(0) }}</td>
                    <td>{{ ((this.dma.total[this.selectedYear].hispanic_tv_hh / this.dma.total[this.selectedYear].total_tv_hh) * this.sampleSize).toFixed(0) }}</td>
                    <td>{{ ((this.dma.total[this.selectedYear].asian_tv_hh / this.dma.total[this.selectedYear].total_tv_hh) * this.sampleSize).toFixed(0) }}</td>
                    <td>{{ this.sampleSize }}</td>
                </tr>
                <tr>
                    <td>Percents</td>
                    <td>{{ ((this.dma.total[this.selectedYear].black_tv_hh / this.dma.total[this.selectedYear].total_tv_hh) * 100 ).toFixed(1) }}%</td>
                    <td>{{ ((this.dma.total[this.selectedYear].hispanic_tv_hh / this.dma.total[this.selectedYear].total_tv_hh) * 100 ).toFixed(1) }}%</td>
                    <td>{{ ((this.dma.total[this.selectedYear].asian_tv_hh / this.dma.total[this.selectedYear].total_tv_hh) * 100 ).toFixed(1) }}%</td>
                    <td>100%</td>
                </tr>
            </tbody>
        </table>
    </div>    
</div>
</template>

<script>
// jquery
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'
import PdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts";
import jsZip from "jszip"
window.JSZip = jsZip
PdfMake.vfs = pdfFonts.pdfMake.vfs
window.PdfMake = PdfMake
import '@/assets/css/dataTables.bulma.min.css'
import '@/assets/css/buttons.bulma.min.css'


export default {
    data: function() {
        return {
            table: null
        }
    },
    name: "table-overall-ethnicity",
    mounted() {
        this.drawTable();
    },
    methods: {
        drawTable() {
            if (this.table) {
                this.table.destroy()
            }

            this.$nextTick(() => {
                this.table = $("#tblOverallEthnicities").DataTable({
                    searching: false,
                    "paging": false,
                    dom: '<B>lfrtip',
                    order: [0,'desc'],
                    buttons: [
                            'copyHtml5',
                            {
                                extend: 'excelHtml5',
                                title: 'countyData'
                            },
                            {
                                extend: 'csvHtml5',
                                title: 'countyData'
                            },
                        ],
                })
            })
        }
    },
    props: ['dma', 'selectedYear', 'sampleSize'],
    watch: {
        dma(newVal, oldVal) {
            this.drawTable()
        }
    }
}
</script>

<style scoped>
</style>