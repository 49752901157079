<template>
<div class="page-activeprojects">
    <div class="columns" style="margin-bottom: 0px">
        <div class="column is-10 is-offset-1 has-text-centered">
            <h1 class="title">Active Projects</h1>
        </div>
    </div>
    
    <div class="columns" style="padding-top: 0px">
        <div class="column is-10 is-offset-1 has-text-centered">
            <h1 class="small">Last Updated: {{ this.lastUpdated }}</h1>
        </div>
    </div>

    <div class="columns" v-if="this.showChart">
        <div class="column is-10 is-offset-1 has-text-centered">
            <ProjectChart 
                :labels="this.labels"
                :datapoints="this.datapoints"
                :dataLabel="this.dataLabel"
            />
        </div>
    </div>

    <div class="columns">
        <div class="column is-10 is-offset-1 has-text-centered">
            <div style="height: 0; margin-bottom: 10px;" class="chart-container">
                
            </div>
        </div>
    </div>

    <div class="columns" v-if="this.projects">
        <div class="column is-10 is-offset-1">
            <table class="table has-text-centered is-narrow is-bordered is-striped" id="tblActiveProjects">
                <thead>
                    <tr>
                        <th class="has-text-centered">Survey Name</th>
                        <th v-for="project in this.projects" :key="project.sg_number" class="has-text-centered" v-on:click="this.chartProject($event, project)">{{ project.title }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Status</td>
                        <td v-for="project in this.projects" :key="project.sg_number">{{ project.status }}</td>
                    </tr>
                    <tr>
                        <td>SG #</td>
                        <td v-for="project in this.projects" :key="project.sg_number">#{{ project.sg_number }}</td>
                    </tr>
                    <tr>
                        <td>Start Date</td>
                        <td v-for="project in this.projects" :key="project.sg_number">{{ processDate(project.start_date) }}</td>
                    </tr>
                    <tr>
                        <td>Target</td>
                        <td v-for="project in this.projects" :key="project.sg_number">{{ project.target }}</td>
                    </tr>
                    <tr>
                        <td><strong>Valids</strong></td>
                        <td v-for="project in this.projects" :key="project.sg_number"><strong>{{ project.qualified }}</strong></td>
                    </tr>
                    <tr>
                        <td>Total Screen Outs</td>
                        <td v-for="project in this.projects" :key="project.sg_number">{{ project.terminates + project.voided }}</td>
                    </tr>
                    <tr>
                        <td>Adjusted Incidence</td>
                        <td v-for="project in this.projects" :key="project.sg_number">{{ (project.incidence * 100).toFixed(1) }}</td>
                    </tr>
                    <tr>
                        <td>Over Quota</td>
                        <td v-for="project in this.projects" :key="project.sg_number">{{ project.overquotas }}</td>
                    </tr>
                    <tr>
                        <td>Suspends</td>
                        <td v-for="project in this.projects" :key="project.sg_number">{{ project.dropouts }}</td>
                    </tr>
                    <tr>
                        <td>Clicks</td>
                        <td v-for="project in this.projects" :key="project.sg_number">{{ project.clickthroughs }}</td>
                    </tr>
                    <tr>
                        <td>Median Length</td>
                        <td v-for="project in this.projects" :key="project.sg_number">{{ secondsToTime(project.median_survey_time) }}</td>
                    </tr>
                    <tr>
                        <td>Overall Avg Length</td>
                        <td v-for="project in this.projects" :key="project.sg_number">{{ secondsToTime(project.mean_survey_time) }}</td>
                    </tr>
                    <tr>
                        <td>Voided Screen Outs</td>
                        <td v-for="project in this.projects" :key="project.sg_number">{{ project.voided }}</td>
                    </tr>
                    <tr>
                        <td>Field Report</td>
                        <td v-for="project in this.projects" :key="project.sg_number"><a :href="project.fieldReportUrl">Link</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
</div>
</template>

<script>
import axios from 'axios'
import ProjectChart from "@/components/ActiveProjects/ProjectChart.vue"

export default {
    name: "ActiveProjects",
    data() {
        return {
            projects: null,
            showChart: false,
            labels: [],
            datapoints: [],
            dataLabel: null,
            lastUpdated: null,
            requestDuration: null
        }
    },
    components: {
        ProjectChart
    },
    mounted() {
        document.title = "Active Projects | SG Tools"
        this.$store.commit("setIsLoading", true)
        this.getSurveys()
    },
    methods: {
        async getSurveys() {
            let url = "activeprojects/"
            await axios.get(url)
                .then(resp => {
                    
                    try {
                        var temp = resp['data']['results']

                        temp.sort(function(a,b) {
                            var dateA = new Date(a.start_date)
                            var dateB = new Date(b.start_date)
                            return dateA - dateB
                        })

                        var projects = temp.filter(value => Object.keys(value).length !== 0);
                        console.log(projects)
                        this.projects = projects
                        this.requestDuration = resp['data']['duration']
                        this.lastUpdated = resp['data']['updated_at'
                        ]
                        this.chartProject(null, this.projects[0])
                        this.$store.commit("setIsLoading", false)
                    } catch {
                        toast({
                            message: "[!] ERROR: Something went wrong getting all DMAs",
                            type: 'is-danger',
                            dismissible: true
                        })
                        this.$store.commit("setIsLoading", false)
                    }
                })
                .catch(err => {
                    console.log('[!] ERROR')
                    console.log(err)
                    this.$store.commit("setIsLoading", false)
                })
        },
        secondsToTime(totalSeconds) {
            if (totalSeconds === -1) {
                return 'No data provided.'
            }

            let minutes = 0;
            if (totalSeconds > 0) {
                minutes = Math.floor(totalSeconds/60);
            }

            let seconds = totalSeconds - minutes * 60;
            seconds = seconds.toFixed(0)
            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            
            return minutes + ":" + seconds;
        },
        processDate(d) {
            console.log(d)
            let date = new Date(d.replace("T"," "))
            let day = date.getDate();
            if (day < 10) {
                day = "0" + day;
            }
            let month = date.getMonth() + 1;
            if (month < 10) {
                month = "0" + month;
            }
            return month + "/" + day;
        },
        convertTZ(date, tzString) {
            // Convert a date into another timezone
            // used when the API returns UTC date and converts to user local time
            if (date === undefined) {
                return date
            }
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
        },
        chartProject(event, project) {
            // convert raw complete counts by day into 
            // labels and datas for the chart.js chart
            // ex Input: project.raw_data = {'08/04/2022': 16, '08/03/2022': 4}
            // ex output:
            //         labels = ["08/03/2022", "08/04/2022"]
            //         dataset = [4, 16]

            var raw_data = project.raw_data
            var keys = Object.keys(raw_data)

            // sort the raw_data keys (aka dates)
            const labels = keys.sort(function(a,b) {
                var dateA = new Date(a)
                var dateB = new Date(b)
                return dateA - dateB
            })

            // build the dataset
            var dataset = []
            for (var idx in labels) {
                let key = labels[idx]
                dataset.push(raw_data[key])
            }
            
            this.labels = labels
            this.dataLabel = project.title
            this.datapoints = dataset
            this.showChart = true
        }
    }
}
</script>

<style scoped>
 #tblActiveProjects {
    font-size: 14px;
 }

 .page-activeprojects {
    margin-bottom: 20px;
 }
</style>
