<template>
    <div class="page-login full-height">
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <h1 class="title">Log in</h1>
                
                <form id="login-form" @submit.prevent="submitForm">
                    <div class="field">
                        <label>Username</label>
                        <div class="control">
                            <input type="text" class="input" v-model="username">
                        </div>
                    </div>

                    <div class="field">
                        <label>Password</label>
                        <div class="control">
                            <input type="password" class="input" v-model="password">
                        </div>
                    </div>

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button blue-button">Log in</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="columns" v-if="timesFailed > 1">
            <div class="column is-6 is-offset-3">
                <router-link class="reset-link" to="/resetpassword">Forgot your password?</router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
.page-login {
    background-color: #000;
    padding-top: 20%;
}

.page-login h1 {
    color: #fff;
}

label {
    color: #fff;
}

.blue-button {
  background-color: rgb(0, 186, 224) !important;
  border: 0px;
  color: white;
}

.reset-link {
    color: white;
}

.reset-link:hover {
  color: rgb(0, 186, 224) !important;
}
</style>

<script>
import axios from 'axios'

export default {
    name: "Login",
    data() {
        return {
            username: '',
            password: '',
            errors: [],
            timesFailed: 0
        }
    },
    mounted() {
        document.title = "Log in | SG Tools"
        if (this.$store.state.isAuthenticated === true) {
            const toPath = this.$route.query.to || '/profile'
            this.$router.push(toPath)
        }
    },
    methods: {
        getCookie(name) {
          if (!document.cookie) {
            return null;
          }

          const xsrfCookies = document.cookie.split(';')
            .map(c => c.trim())
            .filter(c => c.startsWith(name + '='));

          if (xsrfCookies.length === 0) {
            return null;
          }
          return decodeURIComponent(xsrfCookies[0].split('=')[1]);
        },
        async submitForm() {

            // Get CSRF Token
            const csrfToken = this.getCookie('csrftoken');

            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            const formData = {
                username: this.username,
                password: this.password
            }
            this.errors = []

            await axios
                .post("token/login", formData, {headers:{"X-CSRFToken": csrfToken}})
                .then(response => {
                    const token = response.data.auth_token
                    this.$store.commit('setToken', token)

                    axios.defaults.headers.common["Authorization"] = "Token " + token
                    localStorage.setItem("token", token)
                    
                    this.timesFailed = 0
                    this.$store.commit("setIsLoading", false)
                    const toPath = this.$route.query.to || "/profile"
                    this.$router.push(toPath)    
                })
                .catch(error => {
                    console.log(error.response)
                    this.timesFailed = this.timesFailed + 1
                    console.log(this.timesFailed)
                    if (error.response){
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                        this.$store.commit('setIsLoading', false)
                    } else {
                            this.errors.push("Error logging in.")
                            console.log(JSON.stringify(error))
                            this.$store.commit("setIsLoading", false)
                        }
                })
        }
    }
}

</script>
