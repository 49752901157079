<template>
    <div class="page-resetpassword">
        <div class="columns">
            <div class="column is-6 is-offset-3">
                <h1 class="title">Log in</h1>

                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label class=''>Email</label>
                        <div class="control has-icons-left">
                            <input type="email" class="input" v-model="email" placeholder="Email">
                            <span class="icon is-small is-left">
                              <i class="fas fa-envelope"></i>
                            </span>
                        </div>
                    </div>

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button id="btn-login" class="button blue-button">Reset my password</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {toast} from 'bulma-toast'

export default {
    name: "ResetPassword",
    data() {
        return {
            email: '',
            errors: []
        }
    },
    mounted() {
        document.title = "Forgot password? | SG Tools"
    },
    methods: {
        async submitForm() {
            this.$store.commit("setIsLoading", true)

            const formData = {
                email: this.email
            }

            this.$store.commit('setIsLoading', true)

            await axios
                .post("users/reset_password/", formData)
                .then(response => {
                    this.$store.commit("setIsLoading", false)
                    this.$router.push({"name": "ResetSent"})
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }
                        toast({
                        message: JSON.stringify(error.message),
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-center'
                    })
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                        toast({
                            message: JSON.stringify(error),
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'top-center'
                        })
                    }
                })
        }
    }
}
</script>

<style scoped>
    .blue-button {
        background-color: rgb(0, 186, 224);
        color: white;
    }
</style>
