<template>
    <footer class="footer">
      <p class="has-text-centered footer-text">
        SMITHGEIGER, LLC <i class="fa-regular fa-copyright"></i> 2023
      </p>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 20px;
    padding: 1rem;
  }

  .footer {
    background-color: #000 !important;
  }

  .footer-text {
    font-size: 12px;
    padding-bottom: 6px;
  }
</style>
