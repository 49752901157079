<template>
    <div class="page-translator">
        <sidebar />
        <div class="columns has-text-centered">
            <div class="column is-12 has-text-centered">
                <h1 class="title">Upload New Translations</h1>
            </div>
        </div>

        <div class="columns has-text-centered">
            <div class="column is-4 is-offset-4">
                <label class="label">Language</label>
                <select class="input" v-model="this.selectedLanguage">
                    <option v-for="language in this.languages" :key="language.code">{{ language.language }}</option>
                </select>
            </div>
        </div>
        
        <div class="columns has-text-centered">
            <div class="column is-4 is-offset-4">
                <label class="label">Translation File</label>
                <input class="input" type="file" v-on:change="this.handleSelectedFile($event)">
            </div>
        </div>

        <div class="columns has-text-centered">
            <div class="column is-4 is-offset-4">
                <button class="button is-primary" v-on:click="this.uploadFile()">Upload</button>
            </div>
        </div>

        <div class="columns">
            <div class="column is-4 is-offset-4">
                <p>The file can be an XLSX or CSV file. There should be 4 columns. The first 2 can be left blank if desired. But they must exist in the file.
English text must start in Column C.
                </p>
            </div>
        </div>

        <div class="columns">
            <div class="column is-4 is-offset-4">
                <table class="table">
                    <thead>
                        <th>Original Order</th>
                        <th>Resource</th>
                        <th>Primary Language - Collapsed</th>
                        <th>Translation</th>
                    </thead>
                    <tbody>
                        <td>Number</td>
                        <td>Text</td>
                        <td>English Test</td>
                        <td>Spanish Text</td>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
import Sidebar from "@/components/Translator/Sidebar.vue"
import axios from 'axios'
import {toast} from 'bulma-toast'

export default {
    name: 'TranslatorNew',
    components: {
        Sidebar,
        toast
    },
    data() {
        return {
            languages: [{language: "Spanish", code: "spanish"}],
            selectedLanguage: "Spanish",
            file: null,
            resp: null
        }
    },
    mounted() {
        document.title = "Translate File | SG Tools"
    },
    methods: {
        async uploadFile() {
            this.$store.commit("setIsLoading", true)
            var store = this.$store

            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('language', this.selectedLanguage)

            try
            {
                await axios
                .post(
                    'translator/new/',
                    formData
                )
                .then(response => {
                    this.resp = response['data']

                    toast({
                        message: this.resp['message'],
                        type: 'is-' + this.resp['type'],
                        dismissible: true
                    })
                    
                    this.$store.commit("setIsLoading", false)
                }) 
            } catch (error) {
                console.log('[!] ERROR Posting to the API')
                console.log(error)
                store.commit("setIsLoading", false)
            }
        },
        handleSelectedFile(event) {
            this.file = event.target.files[0]
        }
    }
}
</script>
