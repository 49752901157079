<template>
    <div class="page-translator">
        <sidebar />
        <div id="columns has-text-centered">
            <div class="column is-12 has-text-centered">
                <h1 class="title">Translator</h1>
                <p>Select the item you'd like from the menu on the left. </p>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
import Sidebar from "@/components/Translator/Sidebar.vue"

export default {
    name: 'Translator',
    components: {
        Sidebar
    },
    data() {
        return {

        }
    },
    mounted() {
        document.title = "Translator | SG Tools"
    }
}
</script>
